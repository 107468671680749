import React, { Component, Fragment } from 'react';
import {
  Alert,
  Col,
  Row,
} from 'react-bootstrap';
import './App.css';

class App extends Component {
  state = {
    contextType: '',
    conversationId: '',
    customerId: '',
    orgid: '',
    kustomerEmail: '',
    kustomerPhone: '',
  };

  componentDidMount = async () => {
    if (window.Kustomer) {
      window.Kustomer.initialize((context) => {
        // Leave this for troubleshooting issues with Kustomer object
        console.log('Beginning of component did mount. context: ', context);
        this.setState({ contextType: context.type });
        if (context.type !== 'widget') {
          const { customer } = context;
          const existingPhones = customer.attributes.phones;
          const existingEmails = customer.attributes.emails;
          let phone = '';
          let email = '';
          if (Object.keys(existingPhones).length > 0) {
            let phoneString = existingPhones[0].phone;
            phoneString = phoneString.replace(/\D/g, '');
            phone = phoneString.replace('+', '');
            if (phone.length > 10) {
              phone = phone.substring(phone.length - 10);
            }
          }
          if (Object.keys(existingEmails).length > 0) {
            email = existingEmails[0].email;
          }

          this.setState({
            customerId: customer.id,
            kustomerEmail: email,
            kustomerPhone: phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
            orgid: customer.relationships.org.data.id,
          });
        }
        if (context.type === 'conversation') {
          const { conversation, customer } = context;
          const existingPhones = customer.attributes.phones;
          const existingEmails = customer.attributes.emails;
          let phone = '';
          let email = '';
          if (Object.keys(existingPhones).length > 0) {
            let phoneString = existingPhones[0].phone;
            phoneString = phoneString.replace(/\D/g, '');
            phone = phoneString.replace('+', '');
            if (phone.length > 10) {
              phone = phone.substring(phone.length - 10);
            }
          }
          if (Object.keys(existingEmails).length > 0) {
            email = existingEmails[0].email;
          }

          this.setState({
            conversationId: conversation.id,
            kustomerEmail: email,
            kustomerPhone: phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
            orgid: customer.relationships.org.data.id,
          });
        }
      });
    }
    // Leave this for troubleshooting issues with Kustomer object
    console.log('End of component did mount.');
  };

  handleInputChange(event) {
    event.preventDefault();
    this.resetValidation();

    const { target } = event;
    const { id, value } = target;

    if (id === 'contractType') {
      this.getManufacturers();
    }

    if (id !== 'subCategory' && id !== 'newSubcategory') {
      this.setState({
        [id]: value,
        showAppointmentList: false,
        selectedAppointment: null,
      });
    }

    if (id === 'subCategory') {
      const subCategory = this.state.subcategories.find(function (subcategory) {
        return subcategory.subCatDescription === value;
      });
      this.getSymptomCodeList(value);

      this.setState({
        subCategory: value,
        productCode: subCategory.subCatCode,
        productTierCode: subCategory.tierCode,
      });
    }

    if (id === 'newSubcategory') {
      const subCategory = this.state.subcategories.find(function (subcategory) {
        return subcategory.subCatDescription === value;
      });

      this.setState({
        newSubcategory: value,
        newProductCode: subCategory.subCatCode,
        newProductTierCode: subCategory.tierCode,
      });
    }

    if (id === 'brand') {
      const brandDetails = this.state.manufacturers.find(function (
        brandDetail
      ) {
        return brandDetail.brand.toUpperCase() === value;
      });
      this.setState({ brand: value, brandCode: brandDetails.brandCode });
    }
  }

  getWidgetDisplay() {
    return (
      <Fragment>
        <Row className="mt-3">
          <Col md={6}>
            <Alert variant="warning">
              The current context is: {this.state.contextType}. <br />
              {this.state.contextType === 'conversation' && <span>The current conversation id: {this.state.conversationId}.</span>}
              {this.state.contextType === 'customer' && <span>The current customer id: {this.state.customerId}.</span>}
            </Alert>
          </Col>
        </Row>
      </Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.contextType !== '' ? this.getWidgetDisplay():
        <Alert variant="warning">This is not a Kustomer window.</Alert>}
      </React.Fragment>
    );
  }
}

export default App;
